.purchase-table {
    width: 100%;
    border-collapse: collapse;
}

.purchase-table th,
.purchase-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.purchase-table th {
    font-weight: bold;
}

.item-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.item-list li {
    margin-bottom: 4px;
}

.order-divider {
    border: none;
    height: 5px;
    background-color: black;
    margin: 20px 0;
}

.empty-purchases {
    font-family: "Comic Neue", cursive;
}
/* FlavourServingGuide.css */
.flavours-servings-guide {
  background-color: #f8cde3;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guide-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
}

.guide-image img {
  border-radius: 20px;
  width: 300px;
  height: auto;
  object-fit: cover;
}

.guide-text {
  margin-left: 2rem;
  text-align: center;
}

.guide-text h1 {
  font-family: 'Sacramento', cursive;
  font-size: 2.5rem;
  margin: 0;
  width: 500px;
}

.guide-text .guide-top-descp {
  font-family: 'Comic Neue', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: #333;
}

@media (width: 1024px){
  .guide-text .guide-top-descp {
    
    font-size: 0.5rem;
    
  }
};

.forgot-password-page {
  display: flex;
  background-color: white;
}

.forgot-password-page .form-wrapper {
  margin: 5rem 5rem 5rem 5rem;
  width: 100%;
}

.forgot-password-page .form-wrapper .logo {
  margin: 0 0 3rem;
  width: 200px;
}

.forgot-password-page .login-image {
  aspect-ratio: 1024/1365.33;
  max-width: 50%;
  height: auto;
}

.forgot-password-page .form-wrapper .forgot-password-header {
  margin-bottom: 5rem;
  /* width: fit-content; */
}

.forgot-password-form {
  width: 400px;
}

.messages {
  width: auto;
}

.error {
  background-color: white;
}

.error .msgs {
  padding: 10px;
  text-align: center;
  color: #b76e79;
  border: 2px solid #b76e79;
  border-radius: 3px;
  margin: 1rem 0 1rem;
  font-family: "Comic Neue", cursive;
}

.success {
  background-color: white;
}

.success .msgs {
  color: #55ca43;
  border: 2px solid #55ca43;
}

@media (max-width: 1440px) {
  .forgot-password-page .form-wrapper {
    margin: 3rem;
  }

  .forgot-password-page .login-image {
    height: 100%;
  }

  .forgot-password-page .form-wrapper .logo {
    margin: 0 0 3rem;
    width: 130px;
  }
}

@media (max-width: 1024px) {
  .forgot-password-page .form-wrapper {
    margin: 2rem;
  }

  .forgot-password-page .form-wrapper .forgot-password-header {
    margin-bottom: 3rem;
    font-size: 30px;
  }
}

@media (max-width: 900px) {
  .forgot-password-form {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .forgot-password-page .login-image {
    display: none;
  }

  .forgot-password-page .form-wrapper {
    margin: 5rem;
  }
}

@media (max-width: 480px) {
  .forgot-password-page .form-wrapper {
    margin: 3rem;
  }

  .forgot-password-form {
    width: 100%;
  }

  .msgs {
    font-size: 14px;
  }
}

.form-label,
.form-textarea {
  text-align: left;
}

.form-label {
  font-weight: bold;
  font-size: 16px;
}

.form-label .form-textarea {
  height: 150px;
  width: 100%;
  border: 1px solid #a6a6a6;
  padding-left: 0.5rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.form-label .form-textarea:focus {
  outline: none;
  border: 2px solid #b76e79;
  border-radius: 4px;
}

.form-label .form-textarea::placeholder {
  font-family: "Comic Neue", cursive;
}
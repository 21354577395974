.signed-in-group {
  display: flex;
  margin: 0 1.5rem 0 1rem;
  align-items: center;
}

.signed-in-group > *:not(.popup):not(.logout-popup) {
  margin: 1rem 0.5rem;
}

@media (max-width: 1093px) {
  .signed-in-group {
    margin: 0;
  }
}
.logout {
  display: block;
  text-decoration: none;
  color: white;
  padding: 3px 10px;
  margin: 1rem 0;
  font-size: 20px;
  background-color: #b76e79;
  border: 2px solid #b76e79;
  border-radius: 3px;
  text-align: center;
  transition: 0.5s;
  font-family: "Comic Neue", cursive;
  height: 45px;
}

/* Use media query to avoid hover styling when clicked on mobile devices */
@media (hover: hover) {
  .logout:hover {
    background-color: #834b54;
    border-color: #834b54;
  }
}

@media (max-width: 1440px) {
  .logout {
    font-size: 14px;
    padding: 7px 10px;
  }
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border: none;
  background-color: #fbd8d0;
  font-family: "Comic Neue", cursive;
  font-size: 20px;
  font-weight: 600;
  color: darkred;
}

.popup-inner .yes-btn {
  background-color: #b76e79;
  border: 2px solid #b76e79;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Comic Neue", cursive;
  transition: 0.5s;
}

.popup-inner .yes-btn:hover {
  background-color: #c09999;
  border-color: #c09999;
}

.popup-inner .cancel-btn {
  border: 1px solid dark dark grey;
  background-color: grey;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  font-weight: 600;
  font-family: "Comic Neue", cursive;
  transition: 0.5s;
}

.popup-inner .cancel-btn:hover {
  background-color: #c09999;
  border-color: #c09999;
}

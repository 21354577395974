.promotion-popup {
    position: fixed;
    top: 200px;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 9999;
}

.promotion-popup-inner {
    border: 2px solid black;
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fbd8d0;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-family: 'ABeeZee', sans-serif;
}

.promotion-popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border: none;
    background-color: #fbd8d0;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: darkred;
}

.promotion-popup-inner h2 {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
}

.promotion-popup-inner p {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.promotion-yes-btn {
    background-color: #b76e79;
    border: 2px solid #b76e79;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    padding: 5px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
    font-weight: 600;
    font-family: 'ABeeZee', sans-serif;
    transition: 0.5s;
}

.promotion-yes-btn:hover {
    background-color: #834b54;
    border-color: #834b54;
}

.promotion-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.promotion-code,
.copy-btn {
    font-size: 26px;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
}

.copy-btn {
    margin-left: 10px;
    color: white;
    background-color: #b76e79;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 40px;
    position: relative;
}

.copy-btn::after {
    content: "Copy to Clipboard";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: black;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    white-space: nowrap;
}

.copy-btn:hover {
    background-color: #834b54;
}

.success-message {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 9999;
}
.logout-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.logout-popup .popup-inner {
  border: 2px solid #fbd8d0;
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: #fbd8d0;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: "Comic Neue", cursive;
}

.logout-popup .popup-inner .cross {
  margin-left: auto;
  position: relative;
  top: -1rem;
  right: -1rem;
}

.logout-popup .popup-inner .cancel-yes-popup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

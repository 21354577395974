.footer-container {
  background-color: #F3D4E4;
  padding: 40px 20px;
  text-align: center;
  font-family: 'Comic Neue', cursive;
  color: #333;
  position: relative;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: start;
}

.footer-column {
  text-align: left;
  min-width: 150px;
}

.footer-column h4 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

.footer-column ul li a {
  color: inherit;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.social-medias {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.social-link {
  color: inherit;
  text-decoration: none;
}

.social-link:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap; /* Ensure items stay on one line */
  border-top: 1px solid #ddd;
  padding-top: 15px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
  position: relative;
  text-align: center; /* Ensures text is centered within its flex item */
}

.footer-bottom p {
  margin: 3px 0;
  flex-grow: 1; /* Text takes the remaining space */
}

/* Ensure map stays on the right */


.footer-map iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
}


/* Reduce spacing between business hours */
.business-hours p {
  margin: 2px 0;
  line-height: 1.2;
}

/* Responsive behavior */
@media (max-width: 768px) {
  .footer-content {
      display: block;
  }

  .footer-column {
      margin-bottom: 20px;
      text-align: center;
  }

 .footer-bottom{
  font-size: 15px;
 }
}

@media (max-width: 400px) {
  .footer-content {
      display: block;
  }

  .footer-column {
      margin-bottom: 20px;
      text-align: center;
  }

 .footer-bottom{
  font-size: 12px;
 }
}
.scroll-to-top {
  position: fixed;
  bottom: 100px;
  right: 27px;
  z-index: 98;
  cursor: pointer;
  background-color: #b76e79;
  padding: 1rem;
  border-radius: 50%;
  opacity: 0.5;
  justify-content: center;
  aspect-ratio: 1/1;
}

@media (max-width: 768px) {
  .scroll-to-top {
    right: 27px;
  }

  .scroll-to-top img {
    width: 75%;
  }
}

.signup-page {
  display: flex;
  background-color: white;
}

.signup-page .form-wrapper {
  margin: 5rem 5rem 5rem 5rem;
  width: 100%;
}

.signup-page .form-wrapper .logo {
  margin: 0 0 3rem;
  width: 200px;
}

.signup-page .signup-image {
  aspect-ratio: 1024/1365.33;
  max-width: 50%;
  height: auto;
}

.signup-page .form-wrapper .signup-header {
  margin-bottom: 5rem;
  width: fit-content;
}

.signup-form {
  width: 400px;
}

.terms-conditions-check {
  margin: 1.5rem 0 1.5rem;
}

.terms-conditions-check .label {
  font-family: "Comic Neue", cursive;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 3px;
}

.terms-conditions-check .label p {
  margin: 0;
}

.terms-conditions-check .label .terms-and-conditions {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  transition: 0.3s;
  color: #000000;
}

.terms-conditions-check .label .terms-and-conditions:not(.active):hover {
  color: #b76e79;
}

.signup-form .signup-question {
  font-family: "Comic Neue", cursive;
  font-size: 16px;
}

.signup-form .signup-question .signup-link {
  transition: 0.3s;
  color: #000000;
  font-weight: bold;
}

.signup-form .signup-question .signup-link:not(.active):hover {
  color: #b76e79;
}

.messages {
  width: auto;
}

.error {
  background-color: white;
}

.error .msgs {
  padding: 10px;
  text-align: center;
  color: #b76e79;
  border: 2px solid #b76e79;
  border-radius: 3px;
  margin: 1rem 0 1rem;
  font-family: "Comic Neue", cursive;
}

.success {
  background-color: white;
}

.success .msgs {
  color: #55ca43;
  border: 2px solid #55ca43;
}

@media (max-width: 1440px) {
  .signup-page .form-wrapper {
    margin: 3rem;
  }

  .signup-page .signup-image {
    height: 100%;
  }

  .signup-page .form-wrapper .logo {
    margin: 0 0 3rem;
    width: 130px;
  }
}

@media (max-width: 1315px) {
  .signup-page .form-wrapper {
    margin: 2rem;
  }

  .signup-page .form-wrapper .signup-header {
    margin-bottom: 3rem;
    font-size: 30px;
  }

  .signup-page .signup-question {
    font-family: "Comic Neue", cursive;
    font-size: 14px;
  }

  .signup-page .signup-image {
    max-width: 60%;
  }
}

@media (max-width: 1130px) {
  .signup-page .signup-image {
    max-width: 55%;
  }

  .signup-page .form-wrapper .logo {
    margin: 0 0 1rem;
    width: 100px;
  }

  .signup-page .form-wrapper .signup-header {
    margin-bottom: 1rem;
    font-size: 28px;
  }

  .signup-form {
    width: 300px;
  }

  .terms-conditions-check {
    margin: 1rem 0 1rem;
  }
}

@media (max-width: 1045px) {
  .signup-page .signup-image {
    max-width: 65%;
  }
}

@media (max-width: 965px) {
  .signup-page .signup-image {
    max-width: 60%;
  }

  .signup-form {
    width: 250px;
  }
}

@media (max-width: 955px) {
  .signup-page .signup-image {
    display: none;
  }

  .signup-page .form-wrapper {
    margin: 5rem;
  }

  .signup-form {
    width: 300px;
  }
}

@media (max-width: 480px) {
  .signup-page .form-wrapper {
    margin: 3rem;
  }

  .signup-form {
    width: 100%;
  }

  .signup-form .signup-question {
    font-size: 12px;
  }

  .msgs {
    font-size: 14px;
  }

  .terms-conditions-check .label {
    font-size: 14px;
  }
}

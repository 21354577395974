.rose-gold-button {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 16px;
  background-color: #b76e79;
  border: 2px solid #b76e79;
  border-radius: 3px;
  text-align: center;
  transition: 0.5s;
  font-family: "Comic Neue", cursive;
  margin-top: auto;
  width: 100%;
}

@media (hover: hover) {
  .rose-gold-button:hover {
    background-color: #834b54;
    border-color: #834b54;
  }
}

.plan-your-cake {
  background-color: #fce4d6; /* Light pink background */
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  font-family: "Sans-serif";
  color: #4a403a;
  margin: 0 auto 0 auto;
  padding: 2rem 15rem 1rem 15rem;
}

.plan-your-cake h1 {
  text-align: center;
  font-family: "Sacramento", cursive;
  font-size: 96px;
  color: black;
}

.description-and-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.description-plan-cake {
  max-width: 50%;
}

.description p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #4a403a;
}

.image {
  width: 70%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out children */
  align-items: stretch; /* Ensure both children stretch to the same height */
}

.image img {
  width: 100%;
  height: auto;
  flex-grow: 1;
  border-radius: 10px;
}

.cake-tier-texts {
  margin-top: 40px;
  text-align: center;
  font-weight: bold;
}

.cake-tier {
  margin-bottom: 15px;
}

.cake-tier p {
  font-size: 1.1rem;
  color: #4a403a;
  font-weight: 700;
}

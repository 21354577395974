.TermsAndConditionsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PageHeader {
  text-align: center;
  color: #B76E79;
  font-weight: bold;
  font-family: 'Fredoka', sans-serif;
  font-size: 64px;
  margin-top: 20px;
  margin-bottom: 20px;
}


.PolicyList {
  margin-bottom: 50px;
  width: 600px;
  /* display: flex;
  align-items: center; */
}

.Symbol {
  margin-left: 5px;
}

.Policy {
  font-weight: bold;
  font-family: 'Fredoka', sans-serif;
  font-size: 20px;
  background-color: black;
  color: white;
  border: none;
  padding: 25px;
  margin-top: 10px;
  border-radius: 10px;
  text-align: left;
  letter-spacing: 1px;
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.PolicyContent {
  transform: translateY(-20px);
  background-color: transparent;
  font-family: 'Fredoka', sans-serif;
  font-size: 16px;
  border: solid 1px black;
  border-radius: 10px;
  padding: 30px;
  padding-top: 40px;
  width: 100%;
}

/* Mobile view */
@media (max-width: 767px) {
  .PolicyList {
    width: 90%; /* Adjusted width for mobile */
  }
  .TextSection{
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Tablet portrait view */
@media (min-width: 768px) and (max-width: 1023px) {
  .PolicyList {
    width: 70%; /* Adjusted width for tablet in portrait */
  }
  .TextSection{
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Tablet landscape view */
@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .PolicyList {
    width: 50%; /* Adjusted width for tablet in landscape */
  }
  
}
/* FlavourServingSymphony.css */

.flavours-servings-symphony {
  background-color: #fcf9e9; /* Updated background color */
  text-align: center;
  padding: 2rem 1rem;
  border-radius: 8px;
}

.guide-text2 h1 {
  font-family: 'Sacramento', cursive;
  font-size: 3rem;
  font-weight: normal;
  color: #333;
  margin: 0;
}

.guide-text2 p {
  font-family: 'Comic Neue', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: #000000;
  margin-top: 1rem;
  line-height: 1.6;
}

.m-top {
  margin-top: 1rem;
}

img {
  
  border-radius: 8px;
}

.FAQPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FAQ-header {
  color: #b76e79;
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 64px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.FAQ-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 90%;
}

.FAQ-category {
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 24px;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

.selected-category {
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 24px;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  color: #b76e79;
}

.FAQ-questions {
  margin-bottom: 50px;
  width: 600px;
}

.Symbol {
  margin-left: 5px;
}

.Question {
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 20px;
  background-color: black;
  color: white;
  border: none;
  padding: 25px;
  margin-top: 10px;
  border-radius: 10px;
  text-align: left;
  letter-spacing: 1px;
  width: 100%;

  display: flex;
  justify-content: space-between;
}

.Answer {
  transform: translateY(-20px);
  background-color: transparent;
  font-family: "Comic Neue", cursive;
  font-size: 16px;
  border: solid 1px black;
  border-radius: 10px;
  padding: 30px;
  padding-top: 40px;
  width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 650px) {
  .FAQ-header {
    font-size: 50px;
  }

  .FAQ-questions {
    width: 90%;
  }
}

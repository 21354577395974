.gallery-section-wrapper {
  padding: 7rem 0 7rem;
  /* background-color: rgb(240, 223, 205); */
  background-image: url("utils/bg-gold-watercolor.jpg");
}

.gallery-section-wrapper h2 {
  font-family: "Sacramento", cursive;
  text-align: center;
  font-size: 80px;
  color: #daa41a;
  margin: 2rem;
}

.gallery-section-wrapper .gallery-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
}

.gallery-section-wrapper .gallery-row .gallery-column {
  flex: 50%;
  max-width: 40%;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.gallery-section-wrapper
  .gallery-row
  .gallery-column
  .gallery-grid-item-wrapper:not(:first-child) {
  margin-top: 1rem;
}

@media (max-width: 1200px) {
  .gallery-section-wrapper .gallery-row {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .gallery-section-wrapper h2 {
    font-size: 55px;
  }

  .gallery-section-wrapper .gallery-row {
    gap: 0.75rem;
  }

  .gallery-section-wrapper {
    padding: 3rem 0 3rem;
  }

  .gallery-section-wrapper
    .gallery-row
    .gallery-column
    .gallery-grid-item-wrapper:not(:first-child) {
    margin-top: 0.75rem;
  }
}

@media (max-width: 480px) {
  .gallery-section-wrapper .gallery-row {
    gap: 0.3rem;
  }

  .gallery-section-wrapper
    .gallery-row
    .gallery-column
    .gallery-grid-item-wrapper:not(:first-child) {
    margin-top: 0.3rem;
  }

  .gallery-section-wrapper h2 {
    font-size: 38px;
    margin: 1rem;
  }
}

 
  .image-popup-inner img {
    width: 100%; 
    height: auto; 
    display: block;
    margin: 0 auto; 
    max-width: none; 
    padding: 0 10px; 
  }
  

  .image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.image-popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 700px;
    background-color: #FFF;
    border-radius: 25px;
    overflow-y: auto;
    max-height: 1200px;
}

.image-popup-inner .image-close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border: none;
    background-color: #FFF;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    color: red;
    font-weight: bold;
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .image-popup-inner {
    padding: 16px;
  }

  .image-popup-inner .image-close-btn {
    font-size: 16px;
  }
}

/* Styles for tablets (portrait) */
@media (min-width: 768px) and (max-width: 991px) {
  .image-popup-inner {
    max-width: 500px;
  }
}

/* Styles for tablets (landscape) */
@media (min-width: 992px) and (max-width: 1200px) {
  .image-popup-inner {
    max-width: 400px; /* Adjust max-width for tablets in landscape orientation */
  }

  
}

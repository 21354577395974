.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-item {
  border: 2px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(240, 223, 205);
}

.container-containers {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.title-description-container,
.purchase-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.title-description-container {
  text-align: left;
  margin-right: 10px;
}

.purchase-container {
  margin-top: 20px;
  text-align: right;
  align-self: flex-end;
}

.purchase-border {
  border: 1px solid #ccc;
  border-radius: 8px;
}

.video-title {
  font-size: x-large;
  text-align: left;
  margin-bottom: 10px;
}

.price-container {
  text-align: center;
  font-weight: bold;
  font-family: Fredoka;
}

.video {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  height: 400px;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 400px;
  border-radius: 20px;
  overflow: hidden;
}

.video-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(240, 223, 205);
  border-radius: 8px;
  font-family: Fredoka;
}

.video-description {
  width: 100%;
  margin-bottom: 10px;
  overflow: auto;
  color: #908D8D;
  text-align: left;
}

.video-description p {
  text-align: left;
}

.video-item form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-item button {
  min-width: 200px;
}

.video-description-label {
  width: 100%;
  font-size: larger;
  font-weight: bold;
}

.success-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
}

/* Media Query for smaller screen sizes */
@media screen and (max-width: 600px) {
  .video-item {
    width: 100%;
    padding: 10px;
  }

  .container-containers {
    flex-direction: column;
    align-items: flex-start;
  }

  .title-description-container,
  .purchase-container {
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .video-description {
    text-align: center;
  }

  .video {
    height: 200px;
  }
}
.signup {
  display: flex;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  padding: 7px 10px;
  font-family: "Comic Neue", cursive;
  height: 45px;
  align-items: center;
}

.signup:not(.active):hover {
  color: #b76e79;
}

@media (max-width: 1440px) {
  .signup {
    font-size: 14px;
    padding: 7px 10px;
  }
}

p.dropzone-text {
  padding: 0;
  margin: 3px 0 0 0;
  color: #908d8d;
  font-size: 14px;
}

.file-list {
  font-family: "Comic Neue", cursive;
}

.file-list h4 {
  text-align: left;
}

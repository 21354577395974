html, body {
  overflow-x: hidden; /* Prevents any overflow caused by the entire body */
}

.pink-bg {
  background-color: #ffc1dc;
}

.carousel-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
}

.intro-quote {
  margin: auto 1rem;
  width: fit-content;
}

.intro-quote.mobile {
  display: none;
  overflow-x: hidden;
}

.quote-wrapper2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.swiper {
  aspect-ratio: 19/20;
  height: 580px; /* Shrink the height */
  max-height: 500px; /* Adjust max-height to shrink further */
  width: 30%; /* Shrink the width to 20% */
  margin: 50px 50px 50px auto; /* Ensure the margin is appropriate */
  display: inline-block;
}


.swiper-pagination-bullet {
  background-color: white;
}

.swiper-pagination-bullet-active {
  background-color: #daa41a;
}

.swiper-slide {
  /* text-align: center;
  font-size: 18px;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  opacity: 0.55; */
}

.swiper-button-next {
  color: white;
  /* font-size: 25px; */
  font-weight: bold;
}

.swiper-button-prev {
  color: white;
  /* font-size: 30px; */
  font-weight: bold;
}

.swiper-slide img {
  max-width: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  width: 100%;
  height: 100%;
  /* min-height: 460px; */
  z-index: -6;
  margin: 0;
  border-radius: 3000px 3000px 0 0;
  box-shadow: 0px -1px 2px #ffc1dc;
}

@media (min-width: 2800px) {
  .swiper {
    height: 951px;
    width: 30%;
    /* max-width: 1500px; */
  }

  .carousel-wrapper {
    padding: 5rem;
  }
}

@media (max-width: 1930px) {
  .swiper {
    height: 600px;
  }
}

@media (max-width: 1600px) {
  .swiper {
    height: 600px;
  }

  .carousel-wrapper {
    gap: 2rem;
  }

  .swiper {
    aspect-ratio: 19/20;
    height: 450px; /* Shrink the height */
    max-height: 450px; /* Adjust max-height to shrink further */
    width: 30%; /* Shrink the width to 20% */
    margin: 60px 50px 60px 60px; /* Ensure the margin is appropriate */
    display: inline-block;
  }
}



@media (max-width: 1400px) {
  .swiper {
    height: auto;
    width: 35%;
  }
}

@media (max-width: 1240px) {
  .swiper {
    height: auto;
  }
}

@media (max-width: 1134px) {
  .swiper {
    height: auto;
  }
}

@media (max-width: 1024px) {
  .carousel-wrapper {
    gap: 1.5rem;
    padding: 1.5rem 2rem;
  }

  .cake-pop {
    width: 80px;
  }

  .swiper {
    height: auto;
  }


}

@media (max-width: 890px) {
  .carousel-wrapper {
    padding: 2rem 3rem 3rem 3rem;
    gap: 0;
    justify-content: center;
  }

  .intro-quote {
    display: none;
  }
  

  .intro-quote.mobile {
    display: block;
    width: 100%;
    margin: 10%;
    overflow-x: hidden;
    overflow: hidden;
    align-items: center;
  }

  .quote-wrapper2 {
    margin: 0;
  }

  .quote-wrapper2 .quote {
    font-size: 21px;
  }

  .swiper {
    height: auto;
    max-height: 1000px;
    width: 100%;
    margin: auto; /* Ensure the margin is appropriate */
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .swiper-button-next::after {
    font-size: 20px;
  }

  .swiper-button-prev::after {
    font-size: 20px;
  }
  
  .carousel-wrapper {
    padding: 2rem;
  }

  .swiper {
    height: auto;
    max-height: 1000px;
    width: 100%;
    margin: 20px 50px 0px 50px; /* Ensure the margin is appropriate */
    display: inline-block;
  }
  .intro-quote.mobile {
    display: block;
    width: 100%;
    margin: 10%;
    overflow-x: hidden;
    overflow: hidden;
    align-items: center;
    
  }

  .quote-wrapper2 .quote {
    font-size: 16px;
  }
}

.about-us-page {
  display: flex;
  flex-wrap: wrap;
  max-width: fit-content;
  margin: 5rem 9rem 5rem 9rem;
}

.about-us-page > * {
  flex: 50%;
  flex-grow: 1;
}

.about-us-page .portrait-wrapper {
  max-width: 40%;
}

.about-us-page .portrait-wrapper img.about-us-portrait {
  width: 100%;
  object-fit: contain;
  vertical-align: top;
}

.about-us-page .page-content {
  max-width: 680px;
  margin-left: 4rem;
}

.about-us-page .page-content h1 {
  font-family: "Sacramento", cursive;
  font-size: 96px;
  color: #b76e79;
  margin: auto 0 auto;
  padding-top: 10%;
}

.about-us-page .page-content p {
  font-size: 18px;
}

.about-us-title {
  display: flex;
  margin-bottom: -2rem;
  gap: 2rem;
}

.about-us-title .magnolia-flower {
  width: 100px;
  height: auto;
  object-fit: cover;
}


@media (max-width: 1600px) {
  .about-us-page .page-content p {
    font-size: 18px;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 620px;
  }
}

@media (max-width: 1500px) {
  .about-us-page .page-content p {
    font-size: 18px;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 670px;
  }
}


@media (max-width: 1440px) {
  .about-us-page .page-content {
    margin-left: 3rem;
  }

  .about-us-page .page-content h1 {
    font-size: 70px;
    padding-top: 8%;
  }

  .about-us-title {
    margin-bottom: -3rem;
  }

  .about-us-title .magnolia-flower {
    width: 80px;
  }
  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 555px;
  }
  .about-us-page .page-content p {
    font-size: 18px;
  }
}


@media (max-width: 1410px) {
  .about-us-page .page-content p {
    font-size: 18px;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 605px;
  }
}

@media (max-width: 1400px) {
  .about-us-page .page-content p {
    font-size: 18px;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 620px;
  }
}

@media (max-width: 1300px) {
.about-us-page .page-content p {
  font-size: 17px;
}

.about-us-page .portrait-wrapper img.about-us-portrait {
  width: 100%;
  object-fit: contain;
  vertical-align: top;
  height: 625px;
}
}


@media (max-width: 1280px) {
  .about-us-page .page-content p {
    font-size: 17px;
  }
  
  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 690px;
  }
  }


@media (max-width: 1240px) {
  .about-us-page .page-content p {
    font-size: 17px;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    object-fit: contain;
    vertical-align: top;
    height: 700px;
  }
}

@media (max-width: 1024px) {
  .about-us-page {
    margin: 3rem 6rem 3rem 6rem;
  }

  .about-us-page .page-content {
    margin-left: 2.5rem;
    margin-top: 2rem;
  }

  .about-us-page .page-content p {
    font-size: 14.5px;
  }
  .about-us-page .portrait-wrapper img.about-us-portrait {
    width: 100%;
    
    object-fit: contain;
    vertical-align: top;
  }
}




@media (max-width: 892px) {
  .about-us-page {
    margin: 3rem;
  }

  .about-us-page .page-content {
    margin-left: 2rem;
  }

  .about-us-page .page-content h1 {
    font-size: 64px;
  }

  .about-us-page .page-content p {
    font-size: 14px;
  }

  .about-us-title {
    gap: 1rem;
  }

  .about-us-title .magnolia-flower {
    width: 70px;
  }
}

@media (max-width: 701px) {
  .about-us-page .page-content h1 {
    font-size: 48px;
  }
}

@media (max-width: 650px) {
  .about-us-page {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .about-us-page > * {
    flex: 100%;
    flex-grow: 1;
  }

  .about-us-page .portrait-wrapper {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .about-us-page .portrait-wrapper img.about-us-portrait {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

  .about-us-page .page-content {
    max-width: fit-content;
    margin: 0;
  }

  .about-us-page .page-content h1 {
    font-size: 64px;
    text-align: center;
  }

  .about-us-page .page-content p {
    text-align: center;
    font-size: 16px;
  }

  .about-us-title {
    flex-direction: column;
    gap: 0;
    margin-bottom: 2rem;
  }

  .about-us-title .magnolia-flower {
    width: 70px;
    margin: 0 auto 0 auto;
  }
}

.grey-button {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 16px;
  background-color: grey;
  border: 2px solid grey;
  border-radius: 3px;
  text-align: center;
  transition: 0.5s;
  font-family: "Comic Neue", cursive;
  margin-top: auto;
  width: 100%;
}

@media (hover: hover) {
  .grey-button:hover {
    background-color: rgb(51, 51, 51);
    border-color: rgb(51, 51, 51);
  }
}

.GalleryPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PageHeader {
  color: #b76e79;
  font-weight: bold;
  font-size: 64px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.GalleryCategory {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 90%;
}

.category {
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 24px;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

.selected-category {
  font-weight: bold;
  font-family: "Comic Neue", cursive;
  font-size: 24px;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  color: #b76e79;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column; /* Initially set to column for smaller screens */
}

.image-grid img {
  width: calc(
    50% - 20px
  ); /* 50% width for 2 images per row, considering margin */
  max-width: 400px; /* Maximum width */
  height: 400px; /* Fixed height */
  margin: 10px; /* Add margin between images */
  object-fit: Cover; /* Resize images without cropping */
  cursor: pointer;
}

/* For larger screens, display images in two columns */
@media only screen and (min-width: 650px) {
  .image-grid {
    flex-direction: row;
  }
  .image-grid img {
    width: 300px; /* Maintain the fixed width */
    height: 400px; /* Maintain the fixed height */
    margin: 10px; /* Add margin between images */
    object-fit: Cover;
  }
}

/* Styles for mobile devices */
@media (max-width: 767px) {
  .GalleryCategory {
    width: 100%; /* Adjust width for full width on smaller screens */
    margin: 10px 0; /* Adjust margin for spacing */
  }

  .category, .selected-category {
    font-size: 20px; /* Adjust font size */
  }

  .image-grid {
    width: 80%; /* Adjust width for full width on smaller screens */
  }

  .image-grid img {
    width: calc(100% - 20px); /* Adjust image width to fill the container */
    max-width: none; /* Remove max-width */
    height: auto; /* Allow height to adjust proportionally */
    /* Adjust margin for spacing */
  }
}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .GalleryCategory {
    width: 80%; /* Adjust width for tablets */
    margin: 10px 0; /* Adjust margin for spacing */
  }

  .category, .selected-category {
    font-size: 22px; /* Adjust font size */
  }

  .image-grid {
    width: 80%; /* Adjust width for tablets */
  }

  .image-grid img {
    width: calc(50% - 20px); /* Adjust image width for two columns */
  }
}

.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.layout .business-name-wrapper {
  padding: 1rem 3rem 1rem 3rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.layout .business-name-wrapper .logo {
  width: 100px;
  object-fit: contain;
  margin: 0;
}

.layout .business-name-wrapper h1.business-name {
  font-family: "Sacramento", cursive;
  font-size: 64px;
  margin: 0;
}

@media (max-width: 768px) {
  .layout .business-name-wrapper h1.business-name {
    font-size: 58px;
  }
}

@media (max-width: 480px) {
  .layout .business-name-wrapper {
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  .layout .business-name-wrapper .logo {
    min-width: 100px;
  }

  .layout .business-name-wrapper h1.business-name {
    font-size: 24px;
  }
}

.profile-link {
  display: flex;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  padding: 7px 5px;
  font-family: "Comic Neue", cursive;
  align-items: center;
  text-align: center;
  height: 45px;
}

.profile-link:not(.active):hover {
  color: #b76e79;
}

.profile-icon {
  width: 29px;
}

@media (max-width: 1440px) {
  .profile-link {
    font-size: 14px;
  }
}

@media (max-width: 367px) {
  .profile-link {
    display: none;
  }

  .profile-link.menu-open {
    display: flex;
  }
}
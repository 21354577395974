.location-page-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem;
  flex: 1;
}

.location-page-wrapper .location-info {
  justify-content: center;
  align-items: center;
  margin: auto 5rem auto 5rem;
  height: inherit;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.location-page-wrapper .location-info h2 {
  font-size: 64px;
  /* font-family: "Pacifico", cursive; */
  /* font-family: "Dancing Script", cursive; */
  font-family: "Sacramento", cursive;
  /* color: #daa41a; */
  color: black;
}

.location-page-wrapper .location-info p {
  font-size: 24px;
  color: black;
  margin: 0 2rem 0 2rem;
}

.spacer {
  margin: 4rem;
}

/* .location-page-wrapper .location-info p:not(:last-child) {
  margin-bottom: 4rem;
} */

@media (max-width: 768px) {
  .location-page-wrapper {
    flex-direction: column;
    margin: 2rem;
  }

  .location-page-wrapper .location-info {
    margin: 0 0 3rem 2rem;
  }
}

@media (max-width: 480px) {
  .location-page-wrapper {
    flex-direction: column;
    margin: 0.5rem;
  }

  .location-page-wrapper .location-info {
    margin: 2rem;
    padding: 0;
  }

  .location-page-wrapper .location-info h2 {
    font-size: 40px;
  }

  .location-page-wrapper .location-info p {
    font-size: 18px;
    margin: 0 0.5rem 0 0.5rem;
  }

  /* .location-page-wrapper .location-info p:not(:last-child) {
    margin-bottom: 2rem;
  } */
}

.coming-soon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.coming-soon h1.coming-soon-text {
  margin: 5rem 1rem 1rem 1rem;
  font-family: "Sacramento", cursive;
  font-size: 90px;
  text-align: center;
}

@media (max-width: 480px) {
  .coming-soon h1.coming-soon-text {
    margin-top: 3rem;
    font-size: 50px;
  }
}
